import React, { useEffect, useState } from 'react'
import { Modal, Box, Grid, Typography, Button } from "@mui/material";
import {Add as AddIcon, Edit as EditIcon, 
  Delete as DeleteIcon, 
  PersonAddAlt as AssignUserIcon,
  Close as CloseIcon, Save, Check } from '@mui/icons-material';
import InputTextFieldComponent from '../common/input_text_field';
import DropdownComponent from "../common/dropdown";
import { basic, selectBoxWithLabel, textFieldAndShrinkStyle } from '../../../themes/basic';
import CancelButton from '../common/button_cancel';
import CustomButton2 from "../common/button_two";
import SubscriptionGradeList from './subscription_grade_list';
import { red } from '@mui/material/colors';
import CloseButton from '../common/close_button';
import Header from '../common/heading_popup';

const UserModalComponent = ({
  isOpen,
  onClose,
  title,
  userDetails,
  setUserDetails,
  errors,
  roleOptions,
  grades,
  currentGrade,
  setCurrentGrade,
  handleAddGrade,
  handleRemoveGrade,
  handleSaveSubmit,
  initialData = {},
  editUserSubscription,
  mode,
  buttonEnabled = false,
}) => {
  console.log("Mode",mode);
  if(mode == "edit"){
    console.log("Edit UserModalComponent",buttonEnabled);
  }
  return (
    <Modal open={isOpen} onClose={onClose} visible={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "top",
          width: "100vw",
          position: "fixed",
          top: 20,
          left: 0,
        }}
      >
        <Box
          sx={{
            width: 500,
            height: "auto",
            backgroundColor: basic.colorWhite,
            //margin: 'auto',
            padding: 0,
            position: 'relative',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            borderRadius: "5px",
            padding:"0px"
          }}
        >

          <Header title={title} />
          <CloseButton onClose={onClose} />
          

          <Grid container spacing={2} style={{ padding: "10px 20px 20px 20px", marginTop: 0 }}>
            {/* User Name */}
            <Grid item md={12}>
              <InputTextFieldComponent
                required
                label="User Name"
                value={userDetails.userName}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, userName: e.target.value })
                }
                error={Boolean(errors.userName)}
                helperText={errors.userName || ""}
              />
            </Grid>

            {/* User Email */}
            <Grid item md={12}>
              <InputTextFieldComponent
                required
                label="User Email"
                value={userDetails.userEmail}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, userEmail: e.target.value })
                }
                error={Boolean(errors.userEmail)}
                helperText={errors.userEmail || ""}
              />
            </Grid>

            {/* User Role */}
            <Grid item md={12}>
              <DropdownComponent
                label="User Role"
                value={userDetails.userRole}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, userRole: e.target.value })
                }
                options={roleOptions}
                error={Boolean(errors.userRole)}
                helperText={errors.userRole || ""}
              />
            </Grid>

            {/* User Grade */}
            <Grid item md={12}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <InputTextFieldComponent
                  required
                  label="User Grade"
                  value={currentGrade}
                  onChange={(e) => setCurrentGrade(e.target.value)}
                  error={Boolean(errors.grade)}
                  helperText={errors.grade || ""}
                />
                <Button
                  disabled={!currentGrade.trim().length}
                  onClick={handleAddGrade}
                  variant="contained"
                  sx={{
                    minWidth:"40px",
                    mt: 0,
                    height:"37px",
                    backgroundColor:basic.logoGreyColor,
                    padding:"0px",
                    "&:hover": {
                      bgcolor: basic.greenColor,
                      color: basic.colorWhite,
                      iconcolor: basic.greenColor,
                    }
                  }}  
                >
                  <AddIcon fontSize='30' />
                </Button>
              </Box>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>                
                <SubscriptionGradeList
                  grades={grades}
                  editUserSubscription={editUserSubscription}
                  handleRemoveGrade={handleRemoveGrade}
                />                               
              </Box>
            </Grid>

            {/* Date of Birth */}
            <Grid item md={12}>
              <InputTextFieldComponent
                required
                label="Date of Birth"
                type="date"
                value={userDetails.dob}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, dob: e.target.value })
                }
                error={Boolean(errors.dob)}
                helperText={errors.dob || ""}
                InputLabelProps={{ shrink: true }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </Grid>

            {/* Phone Number */}
            <Grid item md={12}>
              <InputTextFieldComponent
                required
                label="Phone Number"
                value={userDetails.phonenumber}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, phonenumber: e.target.value })
                }
                error={Boolean(errors.phonenumber)}
                helperText={errors.phonenumber || ""}
              />
            </Grid>

            {/* Action Buttons */}
            <Grid item md={12} sx={{ textAlign: "right" }}>
            <CancelButton onClick={onClose} aButtonText="Cancel" />
            <CustomButton2 
              aria-label="Save"
              onClick={handleSaveSubmit}
              disabled={buttonEnabled}
              style={{
                marginRight: "5px",
              }}
            >
              <Save fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }} />
              <div style={{ marginLeft: 5 }}>Save</div>
            </CustomButton2>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserModalComponent;
