import React from 'react'
import { Button, Tooltip, Fade, Zoom  } from "@mui/material";
import { basic, selectBoxWithLabel, textFieldAndShrinkStyle } from '../../../themes/basic';
import messageText from "../../../themes/messages.json";

const SubscriptionGradeList = ({ grades, editUserSubscription, handleRemoveGrade }) => {
    const _isSubscribed = (grade) => editUserSubscription.includes(grade);
    return (
        <div>
            {grades.map((grade, index) => {
                const _subscribedExist = _isSubscribed(grade);
                return (
                    <Tooltip
                        key={index}
                        placement="right-start"
                        slots={{
                            transition: Fade,
                          }}
                        title={_subscribedExist ? messageText.editUserPage.gradeAlreadySubscribedTooltipMsg : messageText.editUserPage.removeGradeTooltipMsg}
                        arrow
                    >
                        <Button
                            key={index}
                            variant="outlined"
                            style={{                                       
                                fontSize:basic.contentFontSize, 
                                marginRight:"3px", 
                                minWidth:"auto", 
                                padding:"2px 8px",
                                background: _subscribedExist ? basic.disabledBackgroundColor : basic.greenColor, 
                                border: `1px solid ${_subscribedExist ? basic.disabledBorderColor : basic.greenColor}`,
                                color: _subscribedExist ? basic.disabledColor : basic.colorWhite,     
                                lineHeight:"auto",
                                fontWeight:"normal",      
                                marginBottom:"5px",
                                textTransform:"none",             
                                cursor: _subscribedExist ? "not-allowed" : "pointer",
                            }}
                            sx={{
                                fontFamily:basic.fontFamily
                            }}
                            onClick={() => !_subscribedExist && handleRemoveGrade(grade)}
                            >
                                {_subscribedExist ? `${grade} ` : `${grade} ×`}                           
                        </Button>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export default SubscriptionGradeList;