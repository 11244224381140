/* define the common styles like fontSize, fontFamily and etc... */
export const basic = {
  colorWhite: "#ffffff",
  colorBlack: "#000000",
  greenColor:"#90bb41",
  tableBgColor: "#F3F3F3",
  stepNormalLine:"#bdbdbd",
  logoGreyColor:"#646c7c",
  redColor:"#f4492c",
  redLightColor:"#ff7f7f",
  redHoverColor:"#9e1b1b",
  blueColor:"#3700B3",
  blueHoverColor:"#6200BE",   
  fontSize16: "16px",
  fontSize18: "18px",
  fontSize20: "20px",
  tableHeaderSize:"14px",   
  buttonLightGrey: "#f6f6f6",
  buttonBgColorNormal: "#f6f6f6",
  buttonFontSize:"14px",  
  leftPaneMenuFontSize:"14px",
  contentFontSize:"14px",
  inputFieldDefaultFontSize:"14px",
  labelFontSize:"14px",
  headingFontSize: "16px",
  iconsFontSizeSmall: "16px",
  iconsFontSize: "20px",
  iconsFontSizeLarge: "24px",
  buttonWidth:"120px",
  fontFamily:"CircularStd-Book !important",
  backgroundColorLight: "#F3F3F3",
  errorMsgFontSize:"14px",
  logoHeadingTextSize: "18px",
  disabledBackgroundColor:"#f0f0f0",
  disabledBorderColor:"#ccc",
  disabledColor:"#888"
};
/* define the common styles like fontSize, fontFamily and etc... */

/* sidebar menu hover & active */
export const activeStyle = {
  backgroundColor: basic.greenColor,
  fontFamily:basic.fontFamily,
  color: basic.colorWhite,
  '& .MuiListItemIcon-root': {
    color: basic.colorWhite,
    fontFamily:basic.fontFamily,
    minWidth:"25px",
    '& svg': {
      fontSize: basic.leftPaneMenuFontSize,
      }
  },
  '& .MuiListItemText-root span': {
    fontSize: basic.leftPaneMenuFontSize, // Text font size when active
    fontFamily:basic.fontFamily,
  },
  '&:hover': {
    backgroundColor: basic.greenColor,
    color: basic.colorWhite,
  },
};

export const hoverStyle = {
  color: basic.colorBlack,
  '& .MuiListItemIcon-root': {
    color: basic.colorBlack,
    fontFamily:basic.fontFamily,
    minWidth:"25px",
    '& svg': {
      fontSize: basic.leftPaneMenuFontSize,
      }
  },
  '& .MuiListItemText-root span': {
    fontSize: basic.leftPaneMenuFontSize,
    fontFamily:basic.fontFamily,
  },
  '&:hover': {
    backgroundColor: basic.greenColor,
    color: basic.colorWhite,
    '& .MuiListItemIcon-root': {
      color: basic.colorWhite,
      fontFamily:basic.fontFamily,
      '& svg': {
        color: basic.leftPaneMenuFontSize,
      }
    },
    '& .MuiListItemText-root span': {
      fontSize: basic.leftPaneMenuFontSize,
      fontFamily:basic.fontFamily,
    },
  },
};
/* sidebar menu hover & active */

/* TextField type:date format */
export const textFieldWithLabel = {
  height: "25px",
  width: "105px",
  "& .MuiOutlinedInput-root": {
    "& input": {
      padding: "5px 3px",
      fontSize: basic.contentFontSize,
      fontFamily:basic.fontFamily,
    },
    "& fieldset": {
      borderColor: basic.logoGreyColor
    },
    "&:hover fieldset": {
      borderColor: basic.colorBlack
    },
    "&.Mui-focused fieldset": {
      borderColor: basic.colorBlack
    }
  },
  "& label.Mui-focused": {
    color: basic.colorBlack
  },
  marginRight: "10px",
  marginTop:"5px",
  marginBottom:"5px",
};

export const textFieldAndShrinkStyle = {
    "& .MuiInputLabel-formControl":{
      fontFamily:basic.fontFamily,
      fontSize:basic.inputFieldDefaultFontSize,
    },
    "& .MuiOutlinedInput-input":{
      fontFamily:basic.fontFamily,
      fontSize:basic.inputFieldDefaultFontSize,
    },
    "& .MuiInputLabel-shrink": {
      marginTop:"4px",
      fontFamily:basic.fontFamily,
      fontSize:basic.labelFontSize,
      transform: "translate(13px, -12px) scale(1) !important",
      WebkitTransform: "translate(13px, -12px) scale(1) !important",
      MozTransform: "translate(13px, -12px) scale(1) !important",
      MsTransform: "translate(13px, -12px) scale(1) !important",
      OTransform: "translate(13px, -12px) scale(1) !important",
      color:basic.colorBlack,
    },
    '& .MuiFormHelperText-root': {
      fontFamily:basic.fontFamily,
      fontSize:basic.errorMsgFontSize,
      margin:"0px 0px 0px 0px"
    },
    "& label.Mui-focused": {
      fontFamily:basic.fontFamily,
      fontSize:basic.labelFontSize,
      transform: "translate(13px, -12px) scale(1) !important",
      WebkitTransform: "translate(13px, -12px) scale(1) !important",
      MozTransform: "translate(13px, -12px) scale(1) !important",
      MsTransform: "translate(13px, -12px) scale(1) !important",
      OTransform: "translate(13px, -12px) scale(1) !important",
      color:basic.colorBlack,
    }
}


export const selectBoxWithLabel = {
    fontFamily:basic.fontFamily,    
    fontSize: basic.inputFieldDefaultFontSize,
    height:"40px",
    color: basic.colorBlack,
    borderColor: '#ced4da',
    "& .MuiSelect-select": {
          padding:"8px 10px"
     },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#ced4da',
        },
        '&:hover fieldset': {
            borderColor: '#80bdff',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#80bdff',
        },   
    },

    "&.MuiOutlinedInput-root fieldset legend": {     
       paddingRight: "10px",                                 
    },
   
}
