import React from 'react';
import { Modal, Box, Card, IconButton, Alert, Button, CircularProgress } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Margin, Save } from '@mui/icons-material';
import ButtonComponent from "./button_one";
import HeadingPopup from './heading_popup';  // Assuming HeadingPopup is a custom component
import ButtonCancel from "./button_cancel";
import CustomButton2 from "./button_two";
import { isWidthUp } from '@material-ui/core';
import { MdHeight } from 'react-icons/md';
import CloseButton from './close_button';

import { basic } from '../../../themes/basic';

const CustomModal = ({
  open, // Controls whether the modal is open
  onClose, // Function to close the modal
  onConfirm, // Function to confirm the action
  title, // Modal title
  description, // Modal description or content
  confirmText = 'Confirm', // Confirm button text
  cancelText = 'Cancel', // Cancel button text
  showIcon= false,
  icon,
  defaultHeight,
  loading = false, // Loading state
  successMsg = null, // Success or error message
  successStatus = 'success', // Type of alert (success or error)
  onGoToNext, // Optional function to navigate
  onFinalButtonText= "courses",
}) => {
  const __modalHeight = defaultHeight || 170;
  return (
    
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box
          sx={{
            display: 'flex', // Enables flexbox
            justifyContent: 'center', // Centers horizontally
            alignItems: 'center', // Centers vertically
            width: '100vw', // Full viewport width
            height: '100vh', // Full viewport height
            position: 'fixed', // Fixed positioning
            top: 0,
            left: 0,
          }}
        >
          <Box
            sx={{
              width: 350,
              height: __modalHeight,
              backgroundColor: basic.colorWhite,
              margin: 'auto',
              padding: 0,
              position: 'relative',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              borderRadius: "5px"
            }}
          >
            <CloseButton onClose={onClose} />

            <HeadingPopup title={title} showIcon={showIcon} icon={icon} />

            <Card variant="outlined" style={{ display: 'flex',justifyContent: 'left',padding: '5px 15px', minHeight: '80px',position: 'relative',borderRadius: '0px',textAlign: 'left', fontFamily:basic.fontFamily }}>
              <div style={{ margin:"auto 0px", fontSize: basic.contentFontSize,fontFamily:"inherit" }} dangerouslySetInnerHTML={{ __html: description }} />
            </Card>

            <Box sx={{ display: 'inline-flex', float: "right", justifyContent: 'space-between', textAlign: "right", alignItems: 'center', marginTop: '7px', padding: '0px' }}>
             
              <CustomButton2 variant="contained" onClick={onConfirm}>
                  {confirmText}
                </CustomButton2>

              <CustomButton2 variant="contained" style={{ margin: "0px 15px 0px 0px" }} onClick={onClose}>
                {cancelText}
              </CustomButton2>
              
            </Box>
          </Box>
        </Box>
      </Modal>
  );
};

export default CustomModal;
