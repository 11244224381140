
import axios from 'axios';
import { Modal, TextField, Box, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
//import { Modal } from 'react-bootstrap';
import { Save } from '@mui/icons-material';
//import useGlobalData from '../../../Zustand/GlobalData';
import { useNavigate } from 'react-router-dom';
import PageHeading from '../page_heading';
import { basic } from '../../../themes/basic';
import Header from '../common/heading_popup';
import CloseButton from '../common/close_button';
import InputTextFieldComponent from '../common/input_text_field';
import CancelButton from '../common/button_cancel';
import CustomButton2 from '../common/button_two';
import messages from "../../../themes/messages.json";

export default function PasswordModal({ actionType, show, onClose }) {
    console.log(actionType);
    const [__inputField, __setInputField] = useState({});
    const [__message, __setMessage] = useState({});
    const [__errorMessage, __setErrorMessage] = useState({});
    const navigate = useNavigate();
    const [__errorsMsg, __setErrorsMsg] = useState({});
    const [__currentUserId,__setCurrentUserId] = useState("");

    useEffect(() => {
        if (show) {
            // Reset the fields when the modal is shown
            __setInputField({});
            __setMessage({});
            __setErrorMessage({});
            __setErrorsMsg({});
            const _getUserId = localStorage.getItem("adminId");
            __setCurrentUserId(_getUserId);
        }
    }, [show]);

    const __onChangeTxt = (aField,aValue) => {

        __setInputField(aPrev => ({
            ...aPrev,
            [aField]: aValue
          }));
        /*__setInputField({
            ...__inputField,
            [e.target.name]: e.target.value
        }); */
        __setErrorMessage({});
        __setMessage({});
    };

    const handleClose = () => {
        onClose(); // Use the callback from props to close the modal
        __setErrorMessage({});
        __setInputField({});
        __setMessage({});
    };

    const __forgotPasswordReset = () => {
        __setMessage({});
        __onChangeTxt("userEmail", "");
        //onClose();
    }

    const sendForgotPasswordLink = async (e) => {
        __setErrorsMsg({});
        __setMessage({});
        e.preventDefault();

        // Email validation
        const _emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!__inputField.userEmail){
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                email: messages.forgotPassword.emailMsg
              }));
            return;
        }else if (!_emailRegex.test(__inputField.userEmail)) {
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                email:  messages.forgotPassword.emailValidMsg
            }));
            return;
        }

        const _payload = {
            userEmail: __inputField.userEmail,
        };

        axios.post("/admin/forgotPassword", _payload)
            .then((res) => {
                console.log(res);
                __setMessage({ type:"success", text:res.data.message});
                __setInputField({ userEmail:"" });  
                setTimeout(() => __forgotPasswordReset(), 6000);
            })
            .catch(() => {
                __setErrorsMsg((prevErrors) => ({
                    ...prevErrors,
                    email:  messages.forgotPassword.emailNotRegisterMsg
                }));
                //__setErrorMessage("This Email is not registered");
            });
    };

    const onChangePasswordClick = async () => {
        __setErrorsMsg({});
        __setMessage({});
        const { oldPassword, newPassword, confirmPassword } = __inputField;

        console.log(__inputField.oldPassword);

        if (!oldPassword || !oldPassword.trim()) {
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                oldPassword: messages.changePassword.messageOne
              }));
        } else if (!newPassword || !newPassword.trim()) {      
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                newPassword: messages.changePassword.messageTwo
              }));
        } else if (!confirmPassword || !confirmPassword.trim()) {
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                confirmPassword: messages.changePassword.messageThree
              }));
        } else if (newPassword !== confirmPassword) {
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                confirmPassword: messages.changePassword.messageFour
              }));
        }else if (oldPassword === newPassword) {
            __setErrorsMsg((prevErrors) => ({
                ...prevErrors,
                newPassword: messages.changePassword.messageFive
              }));
        }
       else {
            const _payload = {
                oldPassword,
                newPassword,
                userId:__currentUserId
            };

            await axios.post("/admin/updatePassword", _payload)
            //axios.post("/admin/updateNewPassword", _payload)
                .then((res) => {
                    __setMessage(res.data.message);
                    if (res.data.isPasswordUpdated) {
                        __setInputField({
                            oldPassword:"",
                            newPassword:"",
                            confirmPassword:""
                        });                                        
                        __setMessage({ type:"success", text: res.data.message});
                        setTimeout(() => {  
                            __setMessage({});
                            __setErrorsMsg({});   
                            handleClose();
                        }, 5000);
                    }else{
                        if(res.data.status=="currentPwdError"){
                            __setMessage({ type:"error", text: res.data.message});                            
                        }else if(res.data.status=="newPwdError"){
                            __setMessage({ type:"error", text: res.data.message});
                        }else{
                            __setMessage({ type:"error", text: res.data.message});
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    __setErrorMessage("Error, please try again.");
                });
        }
    };

    return (
        <Modal open={show} onHide={handleClose} centered backdrop="static">
            <Box
            sx={{
                display: 'flex', // Enables flexbox
                justifyContent: 'center', // Centers horizontally
                alignItems: 'center', // Centers vertically
                width: '100vw', // Full viewport width
                height: '100vh',  // Full viewport height
                position: 'fixed', 
                top: 0,
                left: 0,
            }}
            >
                <Box
                sx={{
                    width: 400,
                    height: "auto",
                    backgroundColor: basic.colorWhite,
                    margin: 'auto',
                    padding: 0,
                    position: 'relative',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    borderRadius: "5px"
                }}
                >
                    <Header title={actionType === "changePassword" ? "Change Password" : "Forgot Password"} />
                    <CloseButton onClose={handleClose} />

                    <Grid container spacing={2} style={{ padding: "10px 20px 10px 20px", marginTop: 0 }}>
                        
                        <Grid item md={12}>
                        {actionType === "changePassword" ? (
                            <>
                                {/* CURRENT PASSWORD */}
                                <InputTextFieldComponent 
                                    required
                                    size="medium"
                                    name="oldPassword"
                                    type="password"
                                    label="Current Password" 
                                    variant="outlined" 
                                    value={__inputField.oldPassword}
                                    style={{ width: "100%",margin:"5px 0px 20px 0px" }} 
                                    //onChange={__onChangeTxt}
                                    onChange={(e) =>
                                        __onChangeTxt("oldPassword", e.target.value)
                                    }
                                    error={Boolean(__errorsMsg.oldPassword)}
                                    helperText={__errorsMsg.oldPassword || ""}
                                    
                                />   

                                {/* NEW PASSWORD */}
                                <InputTextFieldComponent 
                                    required
                                    size="medium"
                                    name="newPassword"
                                    type="password"
                                    label="New Password" 
                                    variant="outlined" 
                                    value={__inputField.newPassword}
                                    style={{ width: "100%",margin:"5px 0px 20px 0px" }} 
                                    //onChange={__onChangeTxt}
                                    onChange={(e) =>
                                        __onChangeTxt("newPassword", e.target.value)
                                    }
                                    error={Boolean(__errorsMsg.newPassword)}
                                    helperText={__errorsMsg.newPassword || ""}
                                />   

                                {/* CONFIRM NEW PASSWORD */}
                                <InputTextFieldComponent 
                                    required
                                    size="medium"
                                    name="confirmPassword"
                                    type="password"
                                    label="Confirm New Password" 
                                    variant="outlined" 
                                    value={__inputField.confirmPassword}
                                    style={{ width: "100%",margin:"5px 0px 20px 0px" }} 
                                    //onChange={__onChangeTxt}
                                    onChange={(e) =>
                                        __onChangeTxt("confirmPassword", e.target.value)
                                    }
                                    error={Boolean(__errorsMsg.confirmPassword)}
                                    helperText={__errorsMsg.confirmPassword || ""}
                                />   
                            </>
                                ) : (                                   
                                    <InputTextFieldComponent 
                                    required
                                    size="medium"
                                    name="userEmail"
                                    label="Email" 
                                    variant="outlined" 
                                    value={__inputField.userEmail}
                                    style={{ width: "100%",margin:"5px 0px 20px 0px" }} 
                                    //onChange={__onChangeTxt}
                                    onChange={(e) =>
                                        __onChangeTxt("userEmail", e.target.value)
                                      }
                                    error={Boolean(__errorsMsg.email)}
                                    helperText={__errorsMsg.email || ""}
                                    />   
                                )}                            

                        </Grid>

                        {/* Action Buttons */}
                        <Grid item md={12} style={{ paddingTop:"0px", paddingBottom:"10px"}} sx={{ textAlign: "right" }}>
                            <CancelButton onClick={onClose} aButtonText="Cancel" />

                            <CustomButton2 
                            aria-label={actionType === "changePassword" ? "Submit" : "Submit"}
                            onClick={actionType === "changePassword" ? onChangePasswordClick : sendForgotPasswordLink}
                            style={{
                                marginRight: "5px",
                            }}
                            >
                                <Save fontSize={basic.iconsFontSizeSmall} style={{ marginTop:-1 }} />
                            <div style={{ marginLeft: 5 }}>Submit</div>
                            </CustomButton2>
                        </Grid>
                        <Grid item md={12} style={{ paddingTop:"0px"}} sx={{ textAlign: "center" }}>     
                            {__message.text && (
                                <div 
                                    className="text-center p-3" 
                                    style={{
                                        marginTop: "10px",
                                        color: __message.type === "error" ? "red" : "green",
                                        fontFamily: basic.fontFamily,
                                        fontSize: basic.contentFontSize,
                                    }}
                                >
                                    {__message.text}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>            
        </Modal>
    );
}
