import React, { useEffect, useState } from 'react'
import {
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    TextField,
    Button,
    Grid,
    Box,
    FormControlLabel,
    Alert,
    IconButton,
    useMediaQuery
  } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {Add as AddIcon, Edit as EditIcon, 
    Delete as DeleteIcon, Layers as CategoryIcon, 
    PersonAddAlt as AssignUserIcon, Clear as ClearAllIcon,
    MoreHoriz as DetailsIcon1,
    ReadMore as DetailsIcon2 } from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { basic, textFieldWithLabel } from '../../../themes/basic';
import LoadingOverlay from '../common/loading_overlay';
import DataGridStyle from '../data-table/datagrid_style';
import CustomButton from '../common/button_one';
import CustomButton2 from "../common/button_two";
import MessageSnackbar from '../common/message_snackbar';
import ModelPopup from "../common/modal_popup";
import SubscriptionDuplicateUserList from '../common/subscription_duplicate_user_list';

//Chart Start
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Registering chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend, ChartDataLabels);

export default function Subscription() {
    const __isLargeScreen = useMediaQuery('(min-width:1200px)');
    const __location = useLocation();
    const __navigate = useNavigate();
    const [__allProducts, __setAllProducts] = useState([]);
    const [__selectedProduct, __setSelectedProduct] = useState("all");
    const [__selectedVariant, __setSelectedVariant] = useState("");
    const [__selectedVersion, __setSelectedVersion] = useState("");
    const [__courseDetails, __setCourseDetails] = useState({});
    const [__loading, __setLoading] = useState(false); 
    const [__userSubscriptionData, __setUserSubscriptionData] = useState([]);
    const [__customerAccountId, __setCustomerAccountId] = useState("");
    const [__isAssignUserButtonEnabled, __setIsAssignUserButtonEnabled] = useState(false);
    const [__isUserCheckboxEnabled, __setIsUserCheckboxEnabled] = useState(false);

    const [__assignedUsers, __setAssignedUsers] = useState([]);
    const [__modalOpen, __setModalOpen] = useState(false);
    const [__descriptionMessage, __setDescriptionMessage] = useState(null);

    const [__snackbarOpen, __setSnackbarOpen] = useState(false);
    const [__snackbarMessage, __setSnackbarMessage] = useState("");
    const [__snackbarVariant, __setSnackbarVariant] = useState("success");

    const [__duplicateModalOpen, __setDuplicateModalOpen] = useState(false);
    const [__duplicatesUserData, __setDuplicatesUserData] = useState([]);

    const [__errorStatesForStartDate, __setErrorStatesForStartDate] = useState({});
    const [__errorStatesForEndDate, __setErrorStatesForEndDate] = useState({});

    const [__totalSubscriptions, __setTotalSubscriptions] = useState(0); // Total subscription count
    const [__chartData, __setChartData] = useState({
        labels: ["Total","Active", "Yet to Start", "Expired"], // Status labels
        datasets: [
          {
            label: "Subscription Status",
            data: [0, 0, 0, 0], // Data values will be updated dynamically
            backgroundColor: ["#2196F3","#4CAF50", "#FFC107", "#FF5722"], // Colors for each status            
            borderColor: 'transparent', // Remove the border
            borderWidth: 0,
            barThickness: 20, 
            categoryPercentage: 0.6, 
            barPercentage: 1.0, 
          },
        ],
    });


    const __getCustomerAccountUserData = async (aId) =>{
        try {
            //__setLoading(true);
            const _getUserListWithSubscriptionDetails = await axios.post(`/subscription/getCustomerUserListAndSubscription`, { customerAccountId: aId });
            let __sortedByUserNameData = _getUserListWithSubscriptionDetails.data.data.sort((a, b) => {
                return a.userName.localeCompare(b.userName);
            });                        
            const { statusCounts, total  } = _getSubscriptionData(__sortedByUserNameData);      
            console.log(statusCounts);      
            // Update chart data
            const _formattedData = {
                labels: ["Total","Active", "Yet to Start", "Expired"],
                datasets: [
                  {
                    label: "Subscription Status",
                   // data: [total,statusCounts.active || 0, statusCounts["yet to start"] || 0, statusCounts.expired || 0],
                    data: [total || 0,statusCounts.active || 0, statusCounts["yet to start"] || 0, statusCounts.expired || 0],
                    backgroundColor: ["#2196F3","#4CAF50", "#FFC107", "#FF5722"],
                    //borderColor: 'transparent', // Remove the border
                   // borderWidth: 0,
                   // barThickness: 20, 
                   // categoryPercentage: 0.6, 
                    //barPercentage: 1.0,                    
                  },
                ],
            };

            __setChartData(_formattedData);

            __setUserSubscriptionData(__sortedByUserNameData); 
            //__setLoading(false);            
        }catch(error){
            console.error('Error:', error);
        }finally{
                __setLoading(false);
        }
    }

    const _getSubscriptionData = (aSubscriptionData) => {
        console.log("_getSubscriptionData",aSubscriptionData)
        let statusCounts = { active: 0, "yet to start": 0, expired: 0 };
        let total = 0;

        aSubscriptionData.forEach((user) => {
            const subscriptions = user.subscriptionData || []; // Handle missing subscription data
            subscriptions.forEach((subscription) => {
                total++;
                //const status = subscription.status;
                const status = subscription.status.toLowerCase();

                console.log(user.id,status);
                if (statusCounts[status] !== undefined) {
                    statusCounts[status]++;
                }
            });
        });

        return { statusCounts, total };
    };

    useEffect(() => {

        const __fetchProduct = async () => {
            try{
                //__setLoading(true);  // Set loading state
                const _productData = await axios.get("/product/getAllProducts");
                const _visibleProductsData = _productData.data.data.product.filter(product => product.isVisible);
                __setAllProducts(_visibleProductsData);                
            }catch(aError){
                console.log("error fetch data", aError);
            }finally {
                //__setLoading(false);  // Reset loading state
            }
            
        }
        __fetchProduct();

        const _getCurrentCustomerId = localStorage.getItem("_accountId");
        if(_getCurrentCustomerId){
            __setCustomerAccountId(_getCurrentCustomerId);
            __getCustomerAccountUserData(_getCurrentCustomerId);
        }
       
    }, []);


    const __triggerSnackbar = (message, variant) => {
        __setSnackbarMessage(message);
        __setSnackbarVariant(variant);
        __setSnackbarOpen(true);
    };

    const __formatToDDMMYYYY = (aDateStr) => {
        const _date = new Date(aDateStr);
        const _day = String(_date.getDate()).padStart(2, '0');
        const _month = String(_date.getMonth() + 1).padStart(2, '0');
        const _year = _date.getFullYear();
        return `${_day}-${_month}-${_year}`;
      };

    const __handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        __setSnackbarOpen(false);
        __setModalOpen(false);
      };

    const __handleProductChange = (e) => {
        __setSelectedProduct(e.target.value);
        console.log(__allProducts);
        __setSelectedVariant(""); 
        __setCourseDetails({});
    };
    
    const __handleVariantChange = (e) => {
        __setSelectedVariant(e.target.value);
        __setSelectedVersion("3.0");
        __setCourseDetails({});
        //__setAssignedUsers([]);
    };
    
    const __handleCheckboxChange = (aCourseId) => {     
        __setCourseDetails(prevState => {
            const newState = { ...prevState };
            const courseUpdate = {
                id: aCourseId,
                selected: true,
                startDate: '',
                endDate: ''
            };
            if (newState[aCourseId]) {
                if (newState[aCourseId].selected) {
                    delete newState[aCourseId];
                } else {
                    newState[aCourseId] = courseUpdate;
                }
            } else {
                newState[aCourseId] = courseUpdate;
            }
    
            return newState;
        })
    };

    const isValidDateFormat = (aDateString) => {
        const _regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4})$/;
        return _regex.test(aDateString);
    };
    
    
    const __handleDateChange = (aCourseId, aDateType, aValue) => {
        //const _datePattern = /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/;
        //if (_datePattern.test(aValue)) {
            __setCourseDetails(prevState => ({
                ...prevState,
                [aCourseId]: {
                    ...prevState[aCourseId],
                    [aDateType]: aValue,
                }
            }));
        /*}else{
            __setErrorStatesForStartDate((prev) => ({
                ...prev,
                [aCourseId]: { hasError: true, message: 'Selected date cannot be in the past' },
            }));
        } */
        
    };

    const __getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split("T")[0];
      };

    const __calculatMinEndDate = (aStartDate) => {
        if(!aStartDate) return '';

        const _start = new Date(aStartDate);
        _start.setDate(_start.getDate() + 1);
        return _start.toISOString().split("T")[0];
    }

    const __handleGradeChange = (aUserId, aGrade, aChecked) => {
        console.log(aUserId,aGrade,aChecked);
        __setAssignedUsers(prev => {
            if (aChecked) {
              return [...prev, { id: aUserId, grade: aGrade }];
            } else {
              return prev.filter(item => !(item.id === aUserId && item.grade === aGrade));
            }
          });
    }

    const __validateAssignUsersButton = () => {
        
        if (__assignedUsers.length === 0) {
            return false;
        }
        const _selectedCourses = Object.values(__courseDetails).filter(course => course.selected);
        if (_selectedCourses.length === 0) {
            return false;
        }
        for (let _course of _selectedCourses) {
            if (!_course.startDate || !_course.endDate || _course.startDate >= _course.endDate) {
                return false;
            }
        }

        if (!__selectedProduct || !__selectedVariant || !__selectedVersion) {
            return false;
        }
        
        return true;
    };

    const __validateUserCheckbox = () => {
        
        const _selectedCourses = Object.values(__courseDetails).filter(course => course.selected);
        if (_selectedCourses.length === 0) {
            return false;
        }
        for (let _course of _selectedCourses) {
            if (!_course.startDate || !_course.endDate || _course.startDate >= _course.endDate) {
                return false;
            }
        }

        if (!__selectedProduct || !__selectedVariant || !__selectedVersion) {
            return false;
        }
        
        return true;
    };

    

    useEffect(() => {
        //checking assing users button
        const _isValidAssignUserButton = __validateAssignUsersButton();
        __setIsAssignUserButtonEnabled(_isValidAssignUserButton);

        const _isValidUserCheckbox = __validateUserCheckbox();
        console.log(_isValidUserCheckbox)
        __setIsUserCheckboxEnabled(_isValidUserCheckbox);
    }, [__selectedProduct, __selectedVariant, __selectedVersion, __courseDetails, __assignedUsers]);

   
    const __handleClose = () => __setModalOpen(false);

    const __handleDuplicateUserModalClose = () => __setDuplicateModalOpen(false);
    
    const __assignSubscriptionToUsers = () => {
        const _notifyMessage = `Are you sure you want to assign the selected subscription to the users?`
        __setDescriptionMessage(_notifyMessage);
        __setModalOpen(true);
    }

    const __resetAllData = () => {
        __setSelectedProduct("all");
        __setSelectedVariant("");
        __setSelectedVersion("");
        __setCourseDetails({});
        __setAssignedUsers([]);
    }

    const __assignSubscriptionToUsersConfirm = async () => {
        __setModalOpen(false);

        const _productDetails = {
            productId:__selectedProduct,
            variantId:__selectedVariant,
            version:__selectedVersion
        }

        //Remove the duplicate values from assignedUsers 
        const _uniqueAssignUsersData = __assignedUsers.filter((value, index, self) => 
            index === self.findIndex((t) => t.id === value.id && t.grade === value.grade)
        );

        //Course details converts to array
        const _courseDetailsToArray = Object.entries(__courseDetails).map(([id, details]) => ({
            courseId:id, 
            startDate: details.startDate,
            endDate: details.endDate
          }));


        __setLoading(true);
        const _subscriptionToUsers = await axios.post(`/subscription/assignSubscriptionToUsers`, { customerAccountId: __customerAccountId, productDetails: _productDetails, courseDetails: _courseDetailsToArray, assignUsers: _uniqueAssignUsersData});
        const _response = _subscriptionToUsers.data;
        console.log(_response);
        if(_response.status=="success"){
            __setLoading(false); 
            __resetAllData();
            __getCustomerAccountUserData(__customerAccountId);
            __triggerSnackbar("Successfully assigned the subscriptions.","success"); 

           /* if (_response.duplicates.length > 0) {
                __setDuplicatesUserData(_response.duplicates);
                __setDuplicateModalOpen(true); 
            } else {
                __resetAllData();
                __getCustomerAccountUserData(__customerAccountId);
                __triggerSnackbar("Successfully assigned the subscriptions.","success"); 
            } */
                       
            //__resetAllData();
            //__getCustomerAccountUserData(__customerAccountId);

          
            
            //__triggerSnackbar("Successfully assigned the subscriptions.","success"); 
            
        }else{
            __setLoading(false);
            __triggerSnackbar("Failed to add subscriptions.","error"); 
        }

    }

    //filter the data based on the product, variant
    const __selectedProductData = __allProducts.find(product => product._id === __selectedProduct );
    const __selectedVariantData = __selectedProductData?.productAssociation.filter(variant=> variant.isVisible).find(assoc => assoc.variantId._id === __selectedVariant);

    const isCourseAssigned = (aGrade, aUserId, aCourseId) => {
        console.log(aGrade,aUserId, aCourseId)
        const user = __userSubscriptionData.find(user => user.id === aUserId);
        if (user) {
            const assignedCourse = user.subscriptionData?.find(sub => sub.usergrade === aGrade.trim() && sub.courseId._id === aCourseId);
            return !!assignedCourse;
        }
        return false;
    };

    const [labelColors, setLabelColors] = useState(["#2196F3", "#4CAF50", "#FFC107", "#FF5722"]); // Default label colors


    const __chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y', // Horizontal bar chart
        plugins: {
            tooltip: {
                enabled: false, 
            },
            legend: {
                display: false,
            },
            datalabels: {
                display: true,
                color: 'black', // Data label color
                align: 'end',
                anchor: 'end',
                font: {
                    weight: 'bold',
                    size: 12, // Data label font size
                },
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    display: false, 
                    drawBorder: false,
                },
                ticks: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false, 
                    drawBorder: false,
                },
                ticks: {
                    font: {
                        size: 13, 
                        weight: 'normal',
                    },
                    color: 'black',
                    padding: 10, 
                },
            },
        },
        layout: {
            padding: {
                left: 0,
                right: 30,
                top: 0,
                bottom: 0,
            },
        },
    };

    if (__loading) {
        return <LoadingOverlay loading={__loading} />
    }
    return (
        <div>
            <Container fluid={true} style={{ padding:"0px"}}>
                <Grid container spacing={0}>
                    {/* Manage Product section */}     
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={0}>
                            <Typography style={{fontSize:basic.headingFontSize,fontFamily:basic.fontFamily, marginTop:"0px", textAlign:"center", marginLeft:"0px", marginBottom:"10px", width:"100%", display:"block"}} variant="h4"><strong>Manage Product</strong></Typography>
                        </Grid> 
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={3} sx={{ maxWidth:"60px !important"}}>
                                <Typography sx={{ fontFamily:basic.fontFamily}} style={{fontSize:basic.contentFontSize, marginTop:"6px"}} variant="h4">Product</Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <FormControl fullWidth>                                    
                                    <Select 
                                    sx={{ height:"30px",
                                        fontFamily:basic.fontFamily,
                                        //maxWidth:"240px",
                                        "& .MuiSelect-select": {
                                            fontSize:basic.contentFontSize
                                        }
                                    }}
                                    labelId="product-label"
                                    value={__selectedProduct}
                                    onChange={__handleProductChange}
                                    >
                                        <MenuItem Select value="all" sx={{ fontSize:basic.contentFontSize, fontFamily:basic.fontFamily }}>
                                            All
                                        </MenuItem>
                                        {__allProducts.map(product => (
                                        <MenuItem sx={{ fontSize:basic.contentFontSize, fontFamily:basic.fontFamily }} key={product._id} value={product._id}>{product.productName}</MenuItem>
                                    ))}
                                </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                                            
                        <Grid container spacing={0} style={{ marginTop:"8px"}}>
                            <Grid item xs={12} sm={3} sx={{ maxWidth:"60px !important"}}>
                                 <Typography sx={{fontFamily:basic.fontFamily}} style={{fontSize:basic.contentFontSize, marginTop:"20px"}} variant="h4">Variant</Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <FormControl fullWidth disabled={!__selectedProduct} style={{marginTop:"15px"}}>
                                    <Select
                                    sx={{ height:"30px", fontFamily:basic.fontFamily, 
                                        //maxWidth:"240px", 
                                        "& .MuiSelect-select": { fontSize:basic.contentFontSize }}}
                                    labelId="variant-label"
                                    value={__selectedVariant}
                                    onChange={__handleVariantChange}
                                    >
                                        {__selectedProductData?.productAssociation.filter(variant => variant.isVisible).map(variant => (
                                        <MenuItem sx={{ fontSize:basic.contentFontSize, fontFamily:basic.fontFamily }} key={variant.variantId._id} value={variant.variantId._id}>{variant.variantId.variantName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} style={{ marginTop:"8px"}}>
                            <Grid item xs={12} sm={3} sx={{ maxWidth:"60px !important"}}>
                                 <Typography sx={{fontFamily:basic.fontFamily}} style={{fontSize:basic.contentFontSize, marginTop:"20px"}} variant="h4">Version</Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <FormControl fullWidth disabled={!__selectedVariant} style={{marginTop:"15px"}}>
                                    <Select
                                    sx={{ height:"30px", fontFamily:basic.fontFamily, 
                                        //maxWidth:"240px",
                                        "& .MuiSelect-select": { fontSize:basic.contentFontSize }}}
                                    labelId="variant-label"
                                    value={__selectedVersion}
                                    >
                                        <MenuItem sx={{ fontSize:basic.contentFontSize, fontFamily:basic.fontFamily }} value={__selectedVersion}>{__selectedVersion}</MenuItem>                                        
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* courses section */}                       
                    <Grid item xs={12} sm={4} style={{ padding:"0px 0px", marginLeft:"0px"}}>       
                        <Typography style={{fontSize:basic.headingFontSize, marginTop:"0px", textAlign:"center", marginLeft:"0px", marginBottom:"10px"}} variant="h4"><strong>Courses</strong></Typography>
                        <Box
                            sx={{
                                maxHeight: '136px',
                                maxWidth: "350px",
                                overflowY: 'auto',
                                fontSize: basic.contentFontSize,
                                border: "1px solid grey",
                                borderRadius: "5px",
                                '&::-webkit-scrollbar': {
                                    width: '5px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: basic.greenColor,
                                    borderRadius: '8px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: basic.greenColor,
                                },
                                padding: '5px',
                                mr: "0px",
                            }}
                        >
                            {__selectedVariantData?.courses.length > 0 ? (
                                __selectedVariantData.courses.filter(course => course.isVisible).map(course => {
                                    const _courseId = course.courseId._id;
                                    const _courseDetails = __courseDetails[_courseId] || {};
                                    const _errorState = __errorStatesForStartDate[_courseId] || {};
                                    return (
                                        <div key={_courseId} style={{ display: 'flex', alignItems: 'center', marginLeft:"10px", marginBottom: '2px' }}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checkedIcon={<CheckBoxIcon />}
                                                checked={_courseDetails.selected || false}
                                                onChange={() => __handleCheckboxChange(_courseId)}
                                                sx={{
                                                    fontFamily: basic.fontFamily,
                                                    padding: "0px",
                                                    "&.Mui-checked": {
                                                        color: basic.greenColor,
                                                    },
                                                    "& svg": { fontSize: basic.iconsFontSize }
                                                }}
                                                
                                            />
                                            <Typography sx={{ fontFamily: basic.fontFamily }} style={{ marginRight: '10px', minWidth: "50px", marginLeft: "5px", fontSize: basic.contentFontSize }}>{course.courseId.courseName}</Typography>
                                            <TextField
                                                type="date"
                                                label="Start Date"
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: { marginTop: "4px", fontSize: basic.labelFontSize, fontFamily: basic.fontFamily }
                                                }}
                                                sx={textFieldWithLabel}
                                                variant="outlined"
                                                size="small"
                                                style={{ marginRight: '10px' }}
                                                value={_courseDetails.startDate || ''}
                                                onChange={(e) => __handleDateChange(_courseId, 'startDate', e.target.value)}
                                                
                                                //error={_errorState.hasError}
                                                /*onChange={(aEventObject) =>{
                                                    const _selectedDate = aEventObject.target.value;
                                                    const _todayDate = __getTodayDate(); 
                                                    
                                                    if (new Date(_selectedDate) >= new Date(_todayDate)) {   
                                                        console.log("If ",_todayDate,_selectedDate);                                       
                                                        __handleDateChange(_courseId, 'startDate', aEventObject.target.value)
                                                    
                                                    }else{
                                                        console.log("Else ",_todayDate,_selectedDate);    
                                                        
                                                    }
                                                    
                                                }} */
                                                disabled={!_courseDetails.selected}
                                                inputProps={{
                                                    min: __getTodayDate(),
                                                    maxLength: 10,
                                                    pattern: "(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(\\d{4})"
                                                    //pattern: "\\d{2}-\\d{2}-\\d{4}"
                                                }}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            />
                                            <TextField
                                                type="date"
                                                variant="outlined"
                                                label="End Date"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: { marginTop: "4px", fontSize: basic.labelFontSize, fontFamily: basic.fontFamily }
                                                }}
                                                sx={textFieldWithLabel}
                                                value={_courseDetails.endDate || ''}
                                                onChange={(e) => __handleDateChange(_courseId, 'endDate', e.target.value)}
                                                disabled={!_courseDetails.startDate}
                                                inputProps={{
                                                    min: __calculatMinEndDate(_courseDetails.startDate),
                                                    maxLength: 10,
                                                    pattern: "(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-(\\d{4})"
                                                }}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <Typography style={{ fontSize: basic.contentFontSize, textAlign: "center", display: "block", height: "125px", lineHeight: "125px" }}>No courses available for this product.</Typography>
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ padding:"0px 0px"}}>       
                        <Typography style={{fontSize:basic.headingFontSize, marginTop:"0px", textAlign:"center", marginLeft:"0px", marginBottom:"10px"}} variant="h4"><strong>Subscription Status</strong></Typography>
                        <Box
                            sx={{
                                padding:"0px 0px",
                                textAlign:'center',
                                overflowY: 'auto',
                                
                                fontSize:basic.contentFontSize,
                                '&::-webkit-scrollbar': {
                                    width: '3px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#888',
                                    borderRadius: '8px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: '#555',
                                },
                                mr:"0px",
                            }}
                        >
                                <div 
                                style={{
                                    width: "100%", 
                                    height: "168px", 
                                    margin: "-15px auto 0px auto", 
                                    overflow:"hidden"
                                }}>
                                    <Bar sx={{ padding:"0px", margin:"0px"}} data={__chartData} options={__chartOptions} /> 
                                </div>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12}>
                        <Box
                        sx={{
                        height: "auto",
                        width: "100%",
                        ul: {
                        bgcolor: "#f6f6f6",
                        color: "#ffffff",
                        },
                        }}
                        >

                            <div style={{ display: "flex", position:"absolute", marginTop: 10,zIndex:"1000" }}>                            

                                <CustomButton2
                                onClick={__resetAllData}
                                disabled={__selectedProduct =='all' && __assignedUsers.length === 0}
                                aria-label="Clear All"
                                >
                                <ClearAllIcon fontSize={basic.headingFontSize} style={{ marginTop:-1 }} />
                                <div style={{ marginLeft: 5 }}>Clear All</div>
                                </CustomButton2>


                                <CustomButton disabled={!__isAssignUserButtonEnabled} onClick={__assignSubscriptionToUsers} aria-label="Assign Users">
                                    <AssignUserIcon fontSize={basic.headingFontSize}/>
                                    <div style={{ marginLeft: 10 }}>Assign Users</div>
                                </CustomButton>
                            </div>

                          {/*  <Box
                                sx={{
                                    maxWidth: "1200px",                                    
                                    margin: "auto",
                                }}> */}
                                <DataGridStyle
                                    rows={__userSubscriptionData}
                                    columns={[
                                    {
                                        field: "userName",
                                        headerName: "User Name",
                                        flex: __isLargeScreen ? 1.2 : 1.5,
                                        headerAlign: "left",
                                    },
                                    {
                                        field: "pin",
                                        headerName: "Pin",
                                        flex: 1,
                                        headerAlign: "left",
                                    },
                                    {
                                        field: "userRole",
                                        headerName: "Role",
                                        maxWidth:150,
                                        flex: 1,
                                        headerAlign: "left",
                                    },
                                    {
                                        field: "grade",
                                        headerName: "Grade > Product Name / Variant Name / Course Name",
                                        flex: 3,
                                        headerAlign: "left",
                                        renderCell: (params) => {
                                            const _userId = params.row.id;
                                            const _grades = params.row?.grade ? params.row.grade.split(",") : [];
                                            const _subscriptionData = params?.row?.subscriptionData || [];
                                            const _assigned = __assignedUsers; // Assuming this contains assigned users
                                            const _assignedCourses = Object.keys(__courseDetails);

                                            const isChecked = (aUserId, aGrade) => {
                                                return _assigned.some(item => item.id === aUserId && item.grade === aGrade);
                                            };
                                            return (
                                                <Box>
                                                {_grades.map((grade, index) => {
                                                    
                                                    let __productsForGrade;                                            
                                                    if(__selectedProduct !="all"){
                                                        if(__selectedProduct !="all" && __selectedVariant !=""){
                                                            __productsForGrade = Array.from(new Set(
                                                                _subscriptionData
                                                                .filter(sub => sub?.usergrade === grade.trim() && sub?.variantId?._id === __selectedVariant && sub?.variantId?._id === __selectedVariant)
                                                                .map(sub => sub?.courseId?.courseName)
                                                            ));
                                                        }else{
                                                        __productsForGrade = Array.from(new Set(
                                                            _subscriptionData
                                                            .filter(sub => sub?.usergrade === grade.trim() && sub?.productId?._id === __selectedProduct)
                                                            .map(sub => sub?.productId.productName)
                                                        ));
                                                        }
                                                    }else{
                                                        __productsForGrade = Array.from(new Set(
                                                            _subscriptionData
                                                            .filter(sub => sub?.usergrade === grade.trim())
                                                            .map(sub => sub.productId?.productName)
                                                        ))                                             
                                                    }
                                                    const productNamesDisplay = __productsForGrade.length > 0
                                                    ? __productsForGrade.join(', ')
                                                    : "";

                                                return (
                                                    <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                                        
                                                            <Checkbox
                                                            disabled={!__isUserCheckboxEnabled}
                                                            key={`default-checkbox-${index}`}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={isChecked(_userId, grade.trim())} 
                                                            checkedIcon={<CheckBoxIcon />}
                                                            onChange={(e) => __handleGradeChange(_userId, grade.trim(), e.target.checked)}
                                                            sx={{
                                                            fontFamily: basic.fontFamily,
                                                            padding: "0px",
                                                            "&.Mui-disabled": { opacity:0.3},
                                                            "&.Mui-checked": { color: basic.greenColor },
                                                            "& svg": { fontSize: basic.iconsFontSize },
                                                            }}
                                                            />
                                                    <Typography
                                                        sx={{ fontFamily: basic.fontFamily }}
                                                        style={{ marginRight: "10px", minWidth: "50px", marginLeft: "5px", fontSize: basic.contentFontSize }}
                                                    >
                                                        {grade.trim()} {productNamesDisplay === "" ? "": " - "+productNamesDisplay}
                                                    </Typography>
                                                    </div>
                                                );
                                                })}
                                            </Box>
                                            )
                                        }
                                    },
                                    {
                                        field: "actions",
                                        headerName: "Actions",
                                        flex: 1,
                                        maxWidth: 90,
                                        headerAlign: "left",
                                        disableExport: true,
                                        renderCell: (params) => (
                                        <Box>
                                            <IconButton
                                            aria-label="Details"                                
                                            onClick={() => { 
                                                __navigate(`/schoolDashboard/subscription/details/${params.row.id}`);                                       
                                        }}
                                        sx={{
                                            "&:hover":{
                                                backgroundColor:"inherit !important",
                                            }
                                        }}
                                            >
                                            <DetailsIcon2 style={{fontSize:basic.iconsFontSizeLarge}} titleAccess='Details' />
                                            {/*}<Typography sx={{ fontFamily: basic.fontFamily, fontSize:basic.contentFontSize, color:basic.colorBlack }} variant="body2" style={{ marginLeft: 8 }}>details</Typography> {*/}
                                            </IconButton>                                    
                                        </Box>
                                        ),
                                    },
                                    ]}
                                    //filterModel={filterModel}
                                    disableSelectionOnClick
                                    autoHeight={true}
                                    export={false}
                                    checkboxSelection={false}
                                    //selectionModel={__selectedProduct}
                                    onSelectionModelChange={(selectedItems) => {
                                        //selectedProductFunc(selectedItems)
                                    }}
                                getRowHeight={(params) => {
                                        const _gradeCount = params.model?.grade ? params.model.grade.split(',').length : 1;
                                        const _baseHeight = 25; 
                                        const _extraHeightPerGrade = 20; 
                                        const _calculatedHeight = _baseHeight + (_gradeCount * _extraHeightPerGrade);
                                        return _calculatedHeight;
                                    }}
                                    sx={{
                                        '&.MuiDataGrid-columnHeader': {
                                        //fontSize: __isLargeScreen ? '1.1rem' : '0.9rem',
                                        //color:__isLargeScreen ? 'green' : 'black',
                                        },
                                        '&.MuiDataGrid-cell.MuiDataGrid-cellContent': {
                                        fontSize: __isLargeScreen ? '14px' : '18px',
                                        //color:__isLargeScreen ? 'green' : 'black',
                                        },
                                    }}
                                />
                            {/* </Box> */ }
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            { /* assign users popup */}
            <ModelPopup
                open={__modalOpen}
                onClose={__handleClose}
                onConfirm={__assignSubscriptionToUsersConfirm}
                description={__descriptionMessage}
                title="Alert"    
                loading={__loading}
                showIcon={false}
                icon={Alert}
                confirmText="Confirm"
                cancelText="Cancel"
            />

            { /* message snackbar */}
            <MessageSnackbar
                message={__snackbarMessage}
                variant={__snackbarVariant}
                open={__snackbarOpen}
                onClose={__handleSnackbarClose}
            />

            <SubscriptionDuplicateUserList
                open={__duplicateModalOpen}
                duplicates={__duplicatesUserData}
                onClose={__handleDuplicateUserModalClose}
                //onReselect={handleReselect}
            />

        </div >
    );

}