import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import useGlobalData from '../../Zustand/GlobalData';
import axios from 'axios';
import NavBarPage from '../navBar';
import SideBar from "../sideBar";
import AddIcon from '@mui/icons-material/Add';
import SelectSchoolIcon from '@mui/icons-material/School';
import CategoryIcon from '@mui/icons-material/Category';
import AddAdminIcon from '@mui/icons-material/PersonAddAlt';
import AddPerson from '@mui/icons-material/PersonAdd';
import ReportIcon from '@mui/icons-material/Report';
import MessageIcon from '@mui/icons-material/Message';
import AdminList from '@mui/icons-material/List';
import LeftSidebar from './common/left_sidebar';
import { activeStyle, hoverStyle, basic } from '../../themes/basic';

export default function AdminDashboard() {
    const [selectedBtn, setSelectedBtn] = useState();
    const [manageBtnIsActive, setManageBtnIsActive] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    const { allData, accountId, env } = useGlobalData();

    const handleNavigation = (aNavigation) => {
        setSelectedBtn(aNavigation); // Update the active button state
        navigate(aNavigation); // Navigate to the new route
    };

    useEffect(() => {
        const _getCurrentRouteName = location.pathname.split('/')[2];
        setSelectedBtn(_getCurrentRouteName);
        console.log("AdminDashboardNew",location.pathname.split('/')[2]);
        let isAdminLogged = localStorage.getItem("isAdminLogged");
        if (isAdminLogged === "false" || isAdminLogged === null) {
            navigate('/adminlogin');
        }
    }, [location]);


    const menuItems = [
        //{ name: "addcustomer", text: "Add Customer", icon: <AddIcon />, onClick: () => handleNavigation("addcustomer") },
        //{ name: "addNewSchool", text: "Add New School", icon: <AddIcon />, onClick: () => handleNavigation("addNewSchool") },
        { name: "customer", text: "Manage Customer", icon: <SelectSchoolIcon />, onClick: () => handleNavigation("customer") },
        { name: "product", text: "Manage Product", icon: <CategoryIcon />, onClick: () => handleNavigation("product") },
        { name: "authorDashboard", text: "Author Adminstration", icon: <AddAdminIcon />, onClick: () => handleNavigation("/authorDashboard") },
        { name: "adminRegistration", text: "Add LM Admin", icon: <AddPerson />, onClick: () => handleNavigation("adminRegistration") },
        { name: "admin", text: "Admin List", icon: <AdminList />, onClick: () => handleNavigation("admin") },
        { name: "display_messages", text: "Tara Messages", icon: <MessageIcon />, onClick: () => handleNavigation("display_messages") },
        { name: "reports", text: "Reports", icon: <ReportIcon />, onClick: () => handleNavigation("reports") }
    ];

    const createSecureIdAndRedirect = (e) => {
        let payload = {
            accountId: localStorage.getItem("adminId") || ""
        }
        axios.post("/admin/sendToAuthorAdminPortal", payload)
            .then((res) => {
                window.location.assign(
                    // `https://dev-authoring-admin.learningmatters.ai?secureId=${res.data.secureId}&code=${res.data.code}`
                    `${res.data.url}?secureId=${res.data.secureId}&env=${res.data.environment}`
                );
            })
    }

    return (
        <>
            {}<NavBarPage />{}
            {/*}<SideBar /> {*/}
            <div style={{ marginTop: "50px" }}>
                <Container fluid={true}>
                    <Row className='d-flex'>
                        <Col className='d-flex p-0 flex-column lm-main-dashboard-school' style={{ backgroundColor: basic.buttonLightGrey, maxWidth:"220px" }}>
                            <div className='h-100 d-flex flex-column justify-content-between' style={{ marginTop:"10px"}} >
                                <div>
                                    <LeftSidebar
                                        menuItems={menuItems}
                                        selectedBtn={selectedBtn}
                                        activeStyle={activeStyle}
                                        hoverStyle={hoverStyle}
                                    />
                                    </div>
                            </div>

                        </Col>
                        <Col style={{ marginLeft:"220px", marginTop:"15px"}}>
                            <Outlet />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
