import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from './components/homePage';
import NavBar from './components/navBar';
import LoginPage from './components/LoginPage/LoginPage';
import RegisterPage from './components/RegisterPage/RegisterPage';
import Dashboard from './components/Dashboard/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminLogin from './components/Admin/AdminLogin';
import AdminDashboard from './components/Admin/AdminDashboard';
import Alexalogin from './components/LoginPage/AlexaLogin';
import SelectUserType from './components/LoginPage/SelectUserType';
import Profile_Complete from './components/ProfileCompletion/Profile_Complete';
import { createContext, useEffect, useReducer, useState } from 'react';
import { inititalState, UserContext, userReducer } from './components/Context/Reducer';
import ProfilePage from './components/Dashboard/ProfilePage';
import UserDashboard from './components/Dashboard/AllUsersDetails';
import AuthorRegisterPage from './AuthoringSystem/RegisterPage';
import './components/CSSFiles/common.css';
import './components/CSSFiles/main.css';
import CreateSubscriptionPage from "./components/Dashboard/Subscription/CreateSubscription";
import AssignStudentsToLicense from "./components/Dashboard/Subscription/AssignStudentsToLicense";
import QuestionBankForm from "./components/Admin/QuizAdmin/QuestionBankForm";
import AdminDefaultPage from "./components/Admin/AdminDefaultPage";
import SchoolVerifications from "./components/Admin/SchoolVerifications";
import ManageQuizPage from "./components/Admin/ManageQuiz/ManageQuizPage";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import MainSchoolDashboard from "./components/Admin/AdminSchoolDashboard/MainSchoolDashboard";
import SchoolProfile from "./components/Admin/AdminSchoolDashboard/SchoolProfile";
//import RegisterSchoolAdmin from "./components/Admin/SchoolPages/RegisterSchoolAdmin";
import SchoolLicenses from "./components/Admin/AdminSchoolDashboard/License/SchoolLicenses";
import CreateLicense from "./components/Admin/AdminSchoolDashboard/License/CreateLicense";
// import SchoolUsers from "./components/Admin/AdminSchoolDashboard/SchoolUsers";
import AssignStudentsToLicenseAdmin from "./components/Admin/AdminSchoolDashboard/License/AssignStudentsToLicense";
// import ManageQuiz from "./components/Dashboard/ManageQuiz";
import 'animate.css';
import AdminRegistrationPage from "./components/Admin/AdminRegistrationPage";
import AdminDashboardNew from "./components/Admin/AdminDashboardNew";
import MainAuthorDashboard from "./components/Admin/AuthorPlatformDashboard/MainAuthorDashboard";
import RegisterAuthor from "./components/Admin/AuthorPlatformDashboard/RegisterPages/Register";
import ManageAuthorRoles from "./components/Admin/AuthorPlatformDashboard/DashboardComponents/ManageRolesAuthor";
import useGlobalData from "./Zustand/GlobalData";
import AdminComponent from "./components/Admin/AdminComponent";
import DisplayMessages from "./components/Admin/DisplayMessages";
// export const UserContext = createContext()
import Subscription from "./components/Admin/AdminSchoolDashboard/Subscription";
import SubscriptionDetails from "./components/Admin/AdminSchoolDashboard/subscription_details";
import Product from "./components/Admin/manage-product/product";
import AddProduct from "./components/Admin/manage-product/add_product";
import Variant from "./components/Admin/manage-product/variant";
import Course from "./components/Admin/manage-product/course";
import EditProduct from "./components/Admin/manage-product/edit_product";
import AddVariant from "./components/Admin/manage-product/add_variant";
import EditVariant from "./components/Admin/manage-product/edit_variant";
import AddCourse from "./components/Admin/manage-product/add_course";
import EditCourse from "./components/Admin/manage-product/edit_course";
import Favicon from "react-favicon";
import Reports from "./components/Admin/reports";
//Manage Customers
import Users from "./components/Admin/manage-customer/users";
import AddCustomer from "./components/Admin/manage-customer/add_customer";
import SelectSchool from "./components/Admin/manage-customer/SelectSchool";
import ManageCustomer from "./components/Admin/manage-customer/manage_customer";
import EditCustomer from "./components/Admin/manage-customer/edit_customer";
import ResetPassword from "./components/ForgotPassword/reset_password";

function App() {
  const [state, dispatch] = useReducer(userReducer, inititalState);
  const { env, addEnv } = useGlobalData();

  useEffect(() => {
    let url = window.location.href;
    let domain = (new URL(url));
    console.log("URL", url)
    if (domain.hostname === "localhost" || domain.hostname === "dev-account-admin.learningmatters.ai") {
      addEnv("Dev")
    }
    else {
      addEnv("Test")
    }

  }, [])

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <Router>
        <Routes>
          <Route exact path="/" element={<AdminLogin />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/acnt_type" element={<SelectUserType />} />
          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/alexalogin" element={<Alexalogin />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} >
            <Route path='/admin-dashboard/selectSchool' element={<SelectSchool />} />
            <Route path="/admin-dashboard/questionbankform" element={<QuestionBankForm />} />
            <Route path="/admin-dashboard/AdminDefaultPage" element={<AdminDefaultPage />} />
            <Route path="/admin-dashboard/SchoolVerifications" element={<SchoolVerifications />} />
            <Route path="/admin-dashboard/ManageQuizPage" element={<ManageQuizPage />} />
          </Route>
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/author/register" element={<AuthorRegisterPage />} />
          <Route path="/completeProfile" element={<Profile_Complete />} />
          <Route path="/dashboard" element={<Dashboard />} >
            <Route path='/dashboard' element={<UserDashboard />} />

            <Route path="/dashboard/profile" element={<ProfilePage />} />
            <Route path="/dashboard/completeProfile" element={<Profile_Complete />} />
            <Route path="/dashboard/acnt_type" element={<SelectUserType />} />
            <Route path="/dashboard/user_dashboard" element={<UserDashboard />} />
            <Route path="/dashboard/createSubscription" element={<CreateSubscriptionPage />} />
            <Route path="/dashboard/assignStudentsToLicense" element={<AssignStudentsToLicense />} />
            {/* <Route path="/dashboard/manageQuiz" element={<ManageQuizPage />} /> */}
          </Route>
          {/* <Route path='/selectSchool' element={<SelectSchool />} /> */}
          {/* <Route path='/addNewSchool' element={<RegisterSchoolAdmin />} /> */}
          <Route path='/adminDashboard' element={<AdminDashboardNew />} >
            {/*<Route path='selectcustomer' element={<SelectSchool />} />      
            <Route path='addcustomer' element={<AddCustomer />} />                
            </Route><Route path='addNewSchool' element={<RegisterSchoolAdmin />} /> 
            */}
            <Route path='customer' element={<ManageCustomer />} />      
            <Route path='customer/add' element={<AddCustomer />} />
            <Route path='customer/edit/:customerId' element={<EditCustomer />} />
            <Route path='adminRegistration' element={<AdminRegistrationPage />} />
            <Route path='admin' element={<AdminComponent />} />
            <Route path='display_messages' element={<DisplayMessages />} />
            <Route path='product' element={<Product />} />
            <Route path='product/add' element={<AddProduct />} />    
            <Route path='product/:id' element={<EditProduct />} />
            <Route path='product/variant' element={<Variant />} />
            <Route path='product/variant/add' element={<AddVariant />} />
            <Route path='product/variant/:variantId' element={<EditVariant />} />
            <Route path='product/course' element={<Course />} />
            <Route path='product/course/add' element={<AddCourse />} />
            <Route path='product/course/:courseId' element={<EditCourse />} />
            <Route path='reports' element={<Reports />} />            
          </Route>


          <Route path="/schoolDashboard" element={<MainSchoolDashboard />} >
            <Route path="profile" element={<SchoolProfile />} />
            <Route path="licenses" element={<SchoolLicenses />} />
            {/*<Route path="users" element={<SchoolUsers />} /> */}
            <Route path="users" element={<Users />} />
            <Route path="licenses/createLicense" element={<CreateLicense />} />
            <Route path="licenses/assignStudentsToLicense" element={<AssignStudentsToLicenseAdmin />} />
            <Route path="manageQuiz" element={<ManageQuizPage />} />
            {/* <Route path="addSingleUser" element={<AddSingleUser />} /> */}
            <Route path="admin" element={<AdminComponent />} />
            <Route path="subscription" element={<Subscription />} />
            <Route path="subscription/details/:userId" element={<SubscriptionDetails />} />
          </Route>


          <Route path="/authorDashboard" element={<MainAuthorDashboard />} >
            <Route path="register" element={<RegisterAuthor />} />
            <Route path="manageRoles" element={<ManageAuthorRoles />} />
          </Route>

          {/*<Route path="/forgot-password" element={<ForgotPassword />} />*/}
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;