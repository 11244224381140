import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { basic } from "../../themes/basic";

const PageHeading = ({ title, alignment = "left", fontSize = basic.headingFontSize }) => {
  return (
    <AppBar
      position="static"
      style={{
        background: "inherit",
        boxShadow: "inherit",
        marginBottom:"20px"
      }}
    >
      <Toolbar style={{ padding:"0px", minHeight:"auto"}}>
        <Typography variant="h6" component="div" style={{ fontSize:fontSize,fontWeight:"bold", fontFamily:basic.fontFamily, color: "#000000", textAlign:alignment, width:"100%" }}>
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default PageHeading;
