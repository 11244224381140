import React, { useState, useEffect } from 'react';
import { Box, IconButton, FormControlLabel, Alert, Add as AddIcons, Grid, Typography, Container, Skeleton } from '@mui/material';
import { Download as DownloadIcon, Layers as CategoryIcon} from '@mui/icons-material';
  
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import DataGridStyle from './data-table/datagrid_style';
import { basic } from '../../themes/basic';
import LoadingOverlay from './common/loading_overlay';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const Reports = () => {

  const [__productData, __setProductData] = useState([]);
  const [__reportData, __setReportData] = useState([]);
  const [__loading, __setLoading] = useState(true); 


  const __handleDownload = async (aValue) => {

    if(aValue ==="tara_v2_accountname_vs_userlist_report"){
      __downloadExcel(aValue);
    }

   /* console.log(reportValue);
    try {
      const response = await axios.get(`/reports/download/${reportValue}`, {
        responseType: 'blob', // This ensures that the response is treated as a binary large object (file)
      });

      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' }); // For XLS files
      saveAs(blob, `${reportValue}.xls`); // Save the file with the report's value as the filename

    } catch (error) {
      console.error('Error downloading report:', error);
    } */
  }

  const __downloadExcel = async (aValue) => {
    const dataToExport = [];

    __productData.forEach((user) => {
      user.person.forEach((person) => {
        dataToExport.push({
          "Account Name": user.name,
          Email: user.email,
          Role: user.role,
          'User Name': person.person_name,
          'User Role': person.person_role,
          'User Status': person.status ? 'Active' : 'Inactive',
          'User Pin': person.person_pin,
          //'User UUID': person.person_uuid,
        });
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, aValue+'.xlsx');
  };

  const __renderSkeletonRows = () => {
    return Array.from({ length: 1 }).map((_, index) => ({
      id: "",
      name: "",
      actions: "",
    }));
  };
  

  useEffect(() => {
    const __fetchData = async() => {
      try {
        __setLoading(true);
        const _response = await axios.get('https://skcq1bvj6h.execute-api.ap-southeast-1.amazonaws.com/default/plusTmsApSe1Lambda?env=prod');
        console.log(_response);
        __setProductData(_response.data.Items);
        __setReportData([{id:1,name:"TaraV2_accountName_vs_userList_report",value:"tara_v2_accountname_vs_userlist_report"}]);
        __setLoading(false);
      }catch(aError) {
        console.error('Error fetching data:', aError);
        __setLoading(false);
      }
      
    }

    __fetchData();
    
  }, []);

  

  return (
    <div style={{ marginTop: "0px", padding:"20px 0px 0px 0px", position:"relative" }}> 
      <Grid  spacing={0}>
        <Grid container spacing={0}>
          <Typography style={{fontSize:basic.headingFontSize,fontFamily:basic.fontFamily, marginTop:"0px", textAlign:"left", marginLeft:"0px", marginBottom:"20px", width:"100%", display:"block"}} variant="h4"><strong>Reports List</strong></Typography>
        </Grid> 

        <Box
        sx={{
          height: "auto",
          width: "100%",
          ul: {
            bgcolor: "#f6f6f6",
            color: "#ffffff",
          },
        }}
        >
        <DataGridStyle
          rows={__loading ? __renderSkeletonRows() : __reportData}
          columns={[
            {
              field: "name",
              headerName: "Report Name",
              minWidth: 150,
              flex: 1,
              headerAlign: "left",
              renderCell: (params) => (
                __loading ? <Skeleton variant="text" width="200px" /> : <div style={{ marginLeft: 0, fontSize:basic.contentFontSize }}>{params.value}</div>
              ),
            },
          
            {
              field: "actions",
              headerName: "Actions",
              flex: 1,
              maxWidth: 120,
              headerAlign: "left",
              disableExport: true,
              sortable: false,
              renderCell: (params) => (
                __loading ? (
                  <Skeleton variant="rectangular" width={40} />
                ) : (
                <Box>
                  
                  <IconButton
                    aria-label="Download"
                    onClick={() => __handleDownload(params.row.value)}
                  >
                    <DownloadIcon style={{fontSize:basic.iconsFontSize}} titleAccess='Download' />
                  </IconButton>              
                </Box>
                )
              ),
            },
          ]}
          disableSelectionOnClick
          autoHeight={true}
          showToolbar={false}
          loading={__loading}
          sx={{
            "& .MuiDataGrid-toolbarContainer": {
              display:"none",
            }
          }}
        />
        </Box>
      </Grid>
    </div>
  );
};

export default Reports;
