import React, { useState, useEffect } from 'react';
import { Button, Table, FormControl, FormGroup, FormLabel, Container, Row, Col } from 'react-bootstrap';
import data from '../Admin/Display_Messages_data'
import './DisplayMessages.css'
import axios from 'axios';
import Loader from "react-spinner-loader";
// import "./styles.css";

const constants = ["SKILL_NAME", "VA", "PAUSE_500_MS", "PAUSE_1000_MS", "PAUSE_1S",
    "PAUSE_5S", "PAUSE_10S", "unit", "lesson", "activity", "profilename",
    "PAUSE_2S", "email", "pin", "product", "greetMessage", "repeateCount", "timesText",
    "coursesText", "classText", "grade"
]

const DisplayMessages = () => {

    const [selectedConstant, setSelectedConstant] = useState(constants[0]);
    const [languages, setLangugaes] = useState([])
    const [language, setLangugae] = useState('en')
    const [initialValues, setInitialValues] = useState(null)
    const [masterdata, setMasterdata] = useState({})
    const [init, setInit] = useState({})
    const [messages, setMessages] = useState({})
    const [savedisable, setSavedisable] = useState(true)
    const [canceldisable, setCanceldisable] = useState(true)
    const  [refresh,setRefresh] = useState(true)
    const [loading, setLoading] = useState(false);
    const [enableNewMessage, setEnableNewMessage] = useState(false)
    const [key, setKey] = useState('')
    const [newMessage,setNewMessage]  = useState('')

    console.log(messages)


    useEffect(() => {
        setLoading(true)
        axios.get(`/alexa/messages`)
            .then((res) => {
                console.log("messages data", res.data);
                setLangugaes(Object.keys(res.data))
                setMasterdata(res.data)
                setInitialValues( JSON.parse(JSON.stringify(res.data)))
                setInit(res.data[language]['translation'])
                setMessages(res.data[language]['translation'])
                setLoading(false)
            })
            .catch((error) => {
                console.log("error", error);
                setLoading(false)
            });
    }, [refresh]);


    // const handleChange = (event) => {
    //   console.log(event.target.id)
    //   console.log(event.target.value)
    //   messages[event.target.id] = event.target.value
    //   setSavedisable(false)
    //   setCanceldisable(false)
    //   setMessages({ ...messages })

    //   console.log('init', init)
    //   console.log('messages', messages)
    //   console.log('data', data)
    // }

    const handleChange = (event) => {
        const { id, value } = event.target;
        const updatedMessages = { ...messages, [id]: value };
        setMessages(updatedMessages);
        setSavedisable(false);
        setCanceldisable(false);
    };

    const change = (event) => {
        setSelectedConstant(event.target.value);
    }

    const changeLanguage = (event) => {
        setLangugae(event.target.value);
        setMessages(masterdata[event.target.value]['translation'])
        setInit(masterdata[event.target.value]['translation'])
    }

    const saveChanges = () => {
        setLoading(true)
        setSavedisable(true)
        setCanceldisable(true)
        console.log("init", init)
        console.log("before", masterdata)
        if(key !=='' && newMessage !==''){
            messages[key] = newMessage
         }
        let final = masterdata[language]['translation'] = messages
        console.log("after", final)
        axios.post(`/alexa/messages`, {prevContent: initialValues,newContent:masterdata })
        .then((res) => {
           setRefresh(!refresh)
           console.log(res)
           setLoading(false)
           setNewMessage('')
           setKey('')
        })
        .catch((error) => {
            setLoading(false)
            console.log("error", error);
        });
    }
    const handleNewMessage = () => {
        setEnableNewMessage(!enableNewMessage)
    }
    const handleChangeKey = (event) =>{
     const {value} = event.target
     setKey(value)
     if(key !=='' && newMessage !==''){
        setSavedisable(false);
     }
     setCanceldisable(false);
    }
    const handleChangeMessage = (event) =>{
        const {value} = event.target
        setNewMessage(value)
        if(key !=='' && newMessage !==''){
            setSavedisable(false);
         }
        setCanceldisable(false);
    }

    const cancelChanges = () => {
        setNewMessage('')
        setKey('')
        setMessages(init)
        setSavedisable(true)
        setCanceldisable(true)

    }


    return (

        // <div>
        //   <div>
        //     <label for="save">Cancel changes</label>
        //     <input type='button' value='Cancel' disabled={canceldisable} style={{ backgroundColor: canceldisable ? "lightgrey" : "red" }} onClick={cancelChanges} />
        //     <label for="save">Save changes</label>
        //     <input type='button' value='Save' disabled={savedisable} style={{ backgroundColor: savedisable ? "lightgrey" : "green" }} onClick={saveChanges} />
        //   </div>
        //   <label for="language">Choose a Language:</label>
        //   <select name="languages" id="language" onChange={changeLanguage} value={language} >
        //     {languages.map(val => {
        //       return <option value={val}>{val}</option>
        //     })}
        //   </select>
        //   <label for="cars">Choose a constant:</label>
        //   <select name="cars" id="cars" onChange={change} value={selectedConstant} >
        //     {constants.map(val => {
        //       return <option value={val}>{val}</option>
        //     })}
        //   </select>
        //   <label for="input">copy constant:</label>
        //   <input value={selectedConstant} />
        //   <div></div>
        //   <table>
        //     <tr>
        //       <th style={{ width: "10%" }}>key</th>
        //       <th style={{ width: "45%" }}>previous message</th>
        //       <th style={{ width: "45%" }}>message</th>
        //     </tr>

        //     {
        //       Object.keys(messages).map((gen) => {
        //         return (
        //           <tr key={gen}>
        //             <td key={gen} style={{ color: "red" }}>{gen}</td>
        //             <td><textarea style={{ border: "none", rows: "5" }} value={init[gen]} /></td>
        //             <td><textarea id={gen} onChange={handleChange} style={{ border: "none", rows: "5" }} value={messages[gen]} /></td>
        //           </tr>
        //         )
        //       })
        //     }
        //   </table>
        // </div>
        ( Object.keys(masterdata).length>0 && 
        <Container className='mt-3'>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Cancel</FormLabel>
                        <button className={`ms-3 ${canceldisable ? 'disable_color': 'cancel-button'}`} disabled={canceldisable} onClick={cancelChanges}>
                            Cancel
                        </button>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Save</FormLabel>
                        <button className= {`ms-3 ${savedisable ? 'disable_color': 'save-button'}`} disabled={savedisable} onClick={saveChanges} >
                            Save
                        </button>
                    </FormGroup>
                </Col>
                <Col>

                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Select a Language:</FormLabel>
                        <FormControl className='form-select' as="select" name="languages" id="language" onChange={changeLanguage} value={language} >
                            {languages.map((val) => (
                                <option value={val} key={val}>
                                    {val}
                                </option>
                            ))}
                        </FormControl>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Select a Constant:</FormLabel>
                        <FormControl className='form-select' as="select" onChange={change} value={selectedConstant} >
                            {constants.map((val) => (
                                <option value={val} key={val}>
                                    {val}
                                </option>
                            ))}
                        </FormControl>
                    </FormGroup>
                </Col>
                <Col>
                 {/* <Button style={{ backgroundColor: "grey" }}> 
                  <i className='fa fa-copy'></i>
                 Copy constant </Button> */}
                    <FormGroup>
                        <FormLabel>Copy constant:</FormLabel>
                        <FormControl tyoe style={{ backgroundColor: "white" }} value={`{${selectedConstant}}`} readOnly />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                {enableNewMessage &&
                 <FormGroup>
                        <FormLabel>New Key</FormLabel>
                        <FormControl onChange={handleChangeKey} value={key} as = 'textarea' style={{ backgroundColor: "white" }}  />
                 </FormGroup>
                }
                </Col>
                <Col>
                {enableNewMessage && 
                <FormGroup>
                        <FormLabel>Message</FormLabel>
                        <FormControl onChange={handleChangeMessage} value={newMessage} as = 'textarea' style={{ backgroundColor: "white" }}  />
                 </FormGroup>
                 }
                </Col>
                <Col>
                    <FormGroup>
                    <FormLabel>  </FormLabel>
                       <button className={`ms-3 save-button`} style={{marginTop :'35px'}} onClick={handleNewMessage}>
                            Add New Message
                        </button>
                    </FormGroup>
                </Col>
            </Row>

            <Loader show={loading}/>
            <Table className='mt-3' bordered >
                <thead>
                    <tr>
                        <th>Key</th>
                        <th style={{ width: "45%" }}>Previous Message</th>
                        <th style={{ width: "45%" }}>Message</th>
                    </tr>
                </thead>

                <tbody>
                    {/* {console.log("messages", Object.keys(messages).length)} */}
                    {Object.keys(messages).map((gen) => (
                        < tr key={gen} >
                            <td>
                                <FormControl style={{ backgroundColor: "white" }} className='key-form-control' value={gen} readOnly />
                            </td>
                            <td>
                                <FormControl style={{ backgroundColor: "white" }} as="textarea" className='form-contol' value={init[gen]} readOnly />
                            </td>
                            <td>
                                <FormControl as="textarea" id={gen} onChange={handleChange} className='form-contol' value={messages[gen]} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container >
        )
    )
}


export default DisplayMessages;